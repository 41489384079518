<template>
<div class="details">
    <Header></Header>
    <div class="details-content">
        <div class="content-top">
            <span>*ST金刚：涉嫌违规不披露重要信息罪 法定代表人郭留希被公安机关依法采取强制措施</span>
            <div>
                <span>2022-02-21 09:32 </span>
                <span>来源: 微信公众号《河南资本圈》</span>
            </div>
        </div>
        <div class="content-txt">
            <span>2月20日晚，*ST金刚发布公告，公司于2022年2月18日收到通知，公司法定代表人、董事长郭留希因涉嫌违规不披露重要信息罪被公安机关依法采取强制措施，相关事项尚待公安机关进一步调查。 截至目前，公司经营情况正常。公司于2022年2月18日夜晚召开董事会紧急会议，与会董事一致同意免去郭留希董事长、不再代行董事会秘书职责，选举董事刘淼担任董事长、代行董事会秘书职责，公司管理层将加强经营管理，确保公司经营活动的正常进行。公司将持续关注上述事项的进展情况，并严格按照有关法律、法规的规定和要求，及时履行信息披露义务。 2月20日晚，龙佰集团发布公告，近日，公司与河南省地质矿产勘查开发局第二地质矿产调查院(以下简称“二院”)签署了《战略合作框架协议》。双方根据相关法律、法规之规定，本着诚信、互利、自愿、共赢的精神，遵循双方业务领域优势互补的原则，达成本战略合作框架协议。通过双方的合作，带动矿业行业及相关产业的发展，促进合作双方经济持续、快速、健康发展。 公告称，公司与二院在国内、外区域的钛、锆、石墨、锂等矿产资源的整合、储备、勘查和开发展开合作，是公司保障原材料供应、提升原材料自给率的重要措施，有利于进一步强化公司纵向垂直一体化生产模式，同时将带动矿业行业及相关产业的发展，促进合作双方持续、快速、健康发展，提升双方核心竞争力。本协议为合作框架协议，双方将在合作领域内广泛开展合作，可根据项目特点的不同采取不同的合作方式。</span>
        </div>
        <div class="content-about">
            <div>
                <span>相关资讯</span>
            </div>
            <div class="about-item">
                <div class="item-list">
                    <span>宁德时代“回血”：机构力挺下隐忧亦存</span>
                    <span>2022-02-15 06:14</span>
                </div>
                 <div class="item-list">
                    <span>宁德时代“回血”：机构力挺下隐忧亦存</span>
                    <span>2022-02-15 06:14</span>
                </div>
                 <div class="item-list">
                    <span>宁德时代“回血”：机构力挺下隐忧亦存</span>
                    <span>2022-02-15 06:14</span>
                </div>
                 <div class="item-list">
                    <span>宁德时代“回血”：机构力挺下隐忧亦存</span>
                    <span>2022-02-15 06:14</span>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</div>
</template>

<script>
import Header from '../../../components/mobile/Header.vue'
import Footer from '../../../components/mobile/Footer.vue'
export default {
    name:'Details',
    components:{
      Header,
      Footer
   },
   data(){
       return{}
   },
   created(){
       let id = this.$router.history.current.params.id
       this.getcontent(id)
   },
   methods:{
       getcontent(id){
           this.$http({
                url:'/index/detail',
                params:{
                    id:id
                }
            }).then(res=>{
                console.log(res)
              
            })
       }
   }
}
</script>

<style lang='less' scoped>
.details{
    .details-content{
        width: 690px;
        margin: 40px auto;
        .content-top{
            width: 690px;
            padding-bottom: 19px;
            margin-bottom: 34px;
            border-bottom: 1px solid #BCBCBC;
            >span:nth-child(1){
                font-size: 28px;
                color: #333333;
            }
            >div:nth-child(2){
                display: block;
                margin-top:19px ;
                font-size: 20px;
                color: #999999;
            }
        }
        .content-txt{
            margin-bottom: 50px;
            font-size: 24px;
            color: #666666;
        }
        .content-about{
            margin-bottom: 60px;
            >div:nth-child(1){
                width: 120px;
                padding-bottom: 14px;
                margin-bottom: 14px;
                font-size: 28px;
                color: #12407D;
                border-bottom: 1px solid #12407D;
            }
            .about-item{
                width: 690px;
                .item-list{
                    display: flex;
                    justify-content: space-between;
                    width: 690px;
                    margin-bottom: 9px;
                    font-size: 20px;
                    color: #666666;
                    >span:nth-child(1)::before{
                        display: inline-block;
                        content:'';
                        width: 4px;
                        height: 4px;
                        background-color: #12407D;
                        margin-right: 10px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
</style>